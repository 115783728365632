import React, { useEffect, useState } from 'react';
import { View, Text, Button, StyleSheet } from 'react-native';
import colors from '../../strings/colors';
import SiskusIcon from '../../components/common/SiskusIcon';
import RoundedInput from '../../components/common/RoundedInput';
import RoundedButton from '../../components/common/RoundedButton';
import {  
    useGetBalanceQuery,
    useSendCreditMutation
} from '../../redux/api';
import { useSelector } from 'react-redux';
import Toast from 'react-native-toast-message';


const SendCredit = () => {
  const [recipientPhoneNumber, setRecipientPhoneNumber] = useState('');
  const [amount, setAmount] = useState(0);
  const [status, setStatus] = useState('');
  const [statusColor, setStatusColor] = useState(colors.orange);

  const [credit, setCredit] = useState(0);

  const user_state = useSelector(state => state.user);

  const {data:balance_data,isLoading:balance_isLoading, isSuccess: loadingBalanceSuccessfull, refetch} = useGetBalanceQuery(user_state._id);

  const [sendCredit, sendCreditResult] = useSendCreditMutation();


  const handleRecipientPhoneNumberChange = (text) => {
    setRecipientPhoneNumber(text);
  }

  const handleAmountChange = (text) => {
    setAmount(text);
  }

  const handleSendCredit = () => {
         //validate
            if(recipientPhoneNumber === ''){
                setStatusColor(colors.red);
                Toast.show({
                    type:'error',
                    text1:'Validation Error',
                    text2:'Please enter a recipient phone number'
                });

                return;
            }
            if(amount === 0){
                setStatusColor(colors.red);
                Toast.show({
                    type:'error',
                    text1:'Validation Error',
                    text2:'Please enter an amount'
                });
                return;
            }
            if(amount > credit){
                setStatusColor(colors.red);
                Toast.show({
                    type:'error',
                    text1:'Validation Error',
                    text2:'You do not have enough credit to send'
                });
                return;
            }
            if(amount < 1){
                setStatusColor(colors.red);
                Toast.show({
                    type:'error',
                    text1:'Validation Error',
                    text2:'Please enter a valid amount'
                });
                return;
            }


        sendCredit({senderId:user_state._id,cellphone:recipientPhoneNumber,amount:amount});
  }

    useEffect(() => {
        refetch();
    }, [refetch]);

    useEffect(() => {
        if(loadingBalanceSuccessfull) {
           setCredit(balance_data.balance);
        }
        if(sendCreditResult.isSuccess){
          setStatusColor(colors.green);
          //setStatus(sendCreditResult.data.message);
            if(sendCreditResult.data.success === true){
                Toast.show({
                    type:'success',
                    text1:'Credit sent successfully',
                    text2: sendCreditResult.data.message
                });
                refetch();
            }else{
                Toast.show({
                    type:'error',
                    text1:'Error sending credit',
                    text2: sendCreditResult.data.message
                });
            }
         
        }
        if(sendCreditResult.isError){
          Toast.show({
            type:'error',
            text1:'Error sending credit',
            text2: 'Error sending credit'
        });
        }
    }, [balance_isLoading, sendCreditResult.isLoading]);

  return (
    <View style={styles.container}>
      <SiskusIcon title={'SEND CREDIT'} description={`Your Balance: N$ ${credit}`} />

      <RoundedInput
        placeholder="Recipient Phone Number"
        onChangeText={handleRecipientPhoneNumberChange}
        value={recipientPhoneNumber}
        keyboardType='phone-pad'
      />

        <RoundedInput
            placeholder="Amount"
            onChangeText={handleAmountChange}
            value={amount}
            keyboardType='numeric'
        />

      <RoundedButton
        title="SEND"
        onPress={handleSendCredit}
      />

      <Text style={[styles.statusText, { color: statusColor }]}>{status}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  statusText: {
    marginTop: 20,
    textAlign: 'center',
  },
});

export default SendCredit;
