import {
    SPIN_ONCE,
    SPIN_MAP,
    DECELERATE_MAP
} from '../constants';

const fromArrayPhase = (spinner) => {
    const spinArray = SPIN_MAP[spinner.startNumber];
    spinner.currentIndex = (spinner.currentIndex + 1) % spinArray.length;
    spinner.currentNumber = spinArray[spinner.currentIndex];
    if (spinner.currentIndex === spinArray.length - 1) {
        spinner.spinPhase = 'spinOnce';
    }
};

const spinOncePhase = (spinner) => {
    spinner.spinCount++;
    spinner.currentIndex = (spinner.currentIndex + 1) % SPIN_ONCE.length;
    spinner.currentNumber = SPIN_ONCE[spinner.currentIndex];
    if (spinner.spinCount >= 3) {
        spinner.spinPhase = 'decelerate';
    }
};

const deceleratePhase = (spinner) => {
    const decelerateArray = DECELERATE_MAP[spinner.winningNumber];
    spinner.currentIndex = (spinner.currentIndex + 1) % decelerateArray.length;
    spinner.currentNumber = decelerateArray[spinner.currentIndex];
    if (spinner.currentNumber === spinner.winningNumber) {
        spinner.state = 'idle';
        spinner.spinPhase = null;
        spinner.spinCount = 0;
        spinner.currentIndex = 0;
    }
};

const shouldUpdate = (spinner) => {
    
    const now = Date.now();

    let current_deceleration = 0;
    if(spinner.deceleration > 0) {
        current_deceleration = spinner.i + spinner.i * spinner.i;
    }
    const CONSTANT_SPEED = 25;
    const delay = CONSTANT_SPEED + current_deceleration;

    if (!spinner.lastUpdated || now - spinner.lastUpdated >= delay) {
        spinner.lastUpdated = now;
        return true;
    }

    return false;
};


export const SpinningSystem = (entities,{events,dispatch}) => {
    
    let spinner = entities.spinner;

    if(events.length) {
        events.forEach(event => {
            if(event === 'spin') {
                const numbersCopy = spinner.map((num,index) => { 
                  if(index === 5) {
                    return {
                      ...num,
                      isOn: true
                    }
                  }
                  return num;
                });

                entities.spinner.numbers = numbersCopy;
            }
          
        });

       
    }

    // If it's not time to update yet, just return entities without any changes
    if (!shouldUpdate(spinner)) {
        return entities;
    }

    if (spinner.state === 'spinning') {
        switch (spinner.spinPhase) {
            case 'fromArray':
                fromArrayPhase(spinner);
                break;
            case 'spinOnce':
                spinOncePhase(spinner);
                break;
            case 'decelerate':
                deceleratePhase(spinner);
                spinner.i++;  // Assuming i is a property of spinner and represents loop index
                break;
            default:
                break;
        }
    }

    spinner.numbers = spinner.numbers.map((num, idx) => ({
        ...num,
        isOn: idx === spinner.currentNumber,
    }));

    return entities;
};

  
 
  