/**
 * multiplier.entity.jsx
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2023
 * All rights reserved
 */
export const multiplierEntity = {
    winning_multiplier: 10, // default value
    shuffleIndexes: [], // this will be filled based on the winning multiplier
    lastUpdated: null,
    winning_multipliers: [
        {number:10,isOn:false, width:'15%'},
        {number:50,isOn:false, width:'15%'},
        {number:1000,isOn:true, width:'25%'},
        {number:25,isOn:false, width:'15%'},
        {number:100,isOn:false, width:'20%'},
    ],
};
  