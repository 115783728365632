/**
 * formater.helper.js
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2023
 * All rights reserved
 */

const getFiveDigitsAmount = (amount) => {
    const amountString = amount.toString();
    const amountLength = amountString.length;
    if (amountLength < 5) {
      const diff = 5 - amountLength;
      return '0'.repeat(diff) + amountString;
    }
    return amountString;
};

export {
    getFiveDigitsAmount
}