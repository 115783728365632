import React from 'react';
import { WebView } from 'react-native-webview';

const MyWebView = ({ route }) => {
  const { url } = route.params;

  return (
    <WebView
      source={{ uri: url }}
      startInLoadingState={true}
    />
  );
};

export default MyWebView;
