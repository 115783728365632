import KeypadNumber from "../../../../components/jackpot/KeypadNumber";
import Game from "../Game";
import { KEYS_10K } from "../constants";

const keys = KEYS_10K.keys;

export const keypadEntity = {
   
      keypad_numbers: [
        {number:0, played:false,keys:keys},
        {number:1, played:false,keys},
        {number:2, played:false,keys},
        {number:3, played:false,keys},
        {number:4, played:false,keys},
        {number:5, played:false,keys},
        {number:6, played:false,keys},
        {number:7, played:false,keys},
        {number:8, played:false,keys},
        {number:9, played:false,keys},
        {number:10, played:false,keys},
        {number:11, played:false,keys},
        {number:12, played:false,keys}
      ],
      keyPadNumber: null, 
      keyClicked: null,
      getNumbersPlayed: function() {
            const results = [];
        
            this.keypad_numbers.forEach(item => {
            // Calculate the sum of the keys that are "on" for this number
            const sumForKey = item.keys.reduce((accum, key) => key.isOn ? accum + key.number : accum, 0);
            
            // Only push to results if the sum is non-zero (meaning at least one key was "on")
            if (sumForKey !== 0) {
                results.push({
                number: item.number,
                times: sumForKey
                });
            }
            });
        
            return results;
       },

       turnAllKeysOff: function() {
            // Iterate over each number in the keypad
            for(let i = 0; i < this.keypad_numbers.length; i++) {
                // Turn off all keys for this number
                let newKeys = this.keypad_numbers[i].keys.map((key) => {
                    return {
                        ...key,
                        isOn: false
                    };
                });
                this.keypad_numbers[i].keys = newKeys;
            }
        },

    
  };
  
  
  