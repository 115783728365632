
import React, { useEffect, useState } from 'react';
import AppLoading from 'expo-app-loading';
import { loadFonts } from './font';
import { NavigationContainer } from '@react-navigation/native';
import { Provider } from 'react-redux';
import Navigator from './app/navigation/Navigator';
import store from './app/redux'
import Toast from 'react-native-toast-message';
import { View, TouchableOpacity, Text } from 'react-native';
import colors from './app/strings/colors';




export default function App() {

  const [isReady, setIsReady] = useState(false);

  const loadAppResources = async () => {
    await loadFonts();
    setIsReady(true);
  };



  useEffect(() => {
    loadAppResources();
  }, []);

  if (!isReady) {
    return <AppLoading />;
  }

 
  return (
    <>
      <Provider store={store}>
        <NavigationContainer>
          <Navigator/>
        </NavigationContainer>
      </Provider>
      
      <Toast 
        position='top'
        bottomOffset={50}
        visibilityTime={4000}
        text2NumberOfLines= {3}
      />
    </>
    
  );
}


