/**
 * multipliersSlice.js
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2023
 * All rights reserved
 */

import { createSlice } from "@reduxjs/toolkit";

export const multipliersSlice = createSlice({
    name: 'multipliers',
    initialState: {
        multipliers: [
            {number:10,isOn:false, width:'15%'},
            {number:50,isOn:false, width:'15%'},
            {number:1000,isOn:false, width:'25%'},
            {number:25,isOn:false, width:'15%'},
            {number:100,isOn:false, width:'20%'},
        ]
    },
    reducers: {
       //resetMultipliers
         resetMultipliers: (state) => {
              state.multipliers.forEach(multiplier => multiplier.isOn = false)
         },
        //setOn
        setOn: (state, action) => {
            const multiplier = action.payload
            state.multipliers[multiplier].isOn = true
        },
        //setOff
        setOff: (state, action) => {
            const multiplier = action.payload
            state.multipliers[multiplier].isOn = false
        }

    }
})

export const multipliersSliceActions = multipliersSlice.actions;