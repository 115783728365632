/**
 * colors-constants.js
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2023
 * All rights reserved
 */

import colors from "../../../../strings/colors";

const COLOR_MAP = {
    'green': {
        light: '#076E2E',
        dark: '#033918'
    },
    'pink': {
        light: '#ff00ff',
        dark: '#b300b3'
    },
   'blue': {
        light:colors.dark_blue,
        dark: colors.light_blue
    },
    'yellow': {
        light: '#ffff00',
        dark: '#b3b300'
    },
    'red': {
        light: '#ff0000',
        dark: '#b30000'
    },
    'purple': {
        light: '#800080',
        dark: '#4d004d'
    },
    'orange': {
        light: '#ffa500',
        dark: '#b37400'
    },
    'black': {
        light: '#000000',
        dark: '#000000'
    },
    'white': {
        light: '#ffffff',
        dark: '#ffffff'
    },
    'gold': {
        light: '#ffd700',
        dark: '#b38f00'
    },
    'silver': {
        light: '#c0c0c0',
        dark: '#808080'
    },
    
};

export {
    COLOR_MAP
};