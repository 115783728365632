import React, { useEffect, useState } from 'react';
import { View, Text, TextInput, StyleSheet } from 'react-native';

const DOBPicker = ({ onDOBChange, dob }) => {

  const YEAR = 0;
  const MONTH = 1;
  const DAY = 2;

  let _year = ''
  let _month = ''
  let _day = ''

  console.log(dob);


  if (dob) {
    const dateParts = dob.split('-');
    _year = dateParts[YEAR];
    _month = dateParts[MONTH];
    _day = dateParts[DAY].split('T')[0];
  }

  const [day, setDay] = useState(_day);
  const [month, setMonth] = useState(_month);
  const [year, setYear] = useState(_year);

  useEffect(() => {
    if (day.length === 2 && month.length === 2 && year.length === 4) {
      const formattedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
      onDOBChange(formattedDate);
      console.log(formattedDate);
    }
  }, [day, month, year]);

  return (
    <View style={styles.container}>
      <Text style={styles.label}>Date of Birth</Text>
      <View style={styles.inputRow}>
        <TextInput
          style={styles.input}
          placeholder="DD"
          keyboardType="numeric"
          placeholderTextColor="gray"
          maxLength={2}
          value={day}
          onChangeText={text => setDay(text)}
        />
        <TextInput
          style={[styles.input, styles.inputMiddle]}
          placeholder="MM"
          keyboardType="numeric"
          maxLength={2}
          value={month}
          placeholderTextColor="gray"
          onChangeText={text => setMonth(text)}
        />
        <TextInput
          style={styles.input}
          placeholder="YYYY"
          keyboardType="numeric"
          placeholderTextColor="gray"
          maxLength={4}
          value={year}
          onChangeText={text => setYear(text)}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginBottom: 15,
  },
  label: {
    fontFamily: 'Poppins-Bold',
    fontSize: 16,
    marginBottom: 8,
  },
  inputRow: {
    flexDirection: 'row',
  },
  input: {
    fontFamily: 'Poppins-Bold',
    width: '30%',
    borderRadius: 25,
    backgroundColor: 'lightgray',
    paddingHorizontal: 16,
    padding: 12,
    fontSize: 16,
    borderColor: 'transparent',
    borderWidth: 0
  },
  inputMiddle: {
    marginLeft: 5,
    marginRight: 5,
  }
});

export default DOBPicker;
