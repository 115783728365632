

import React, { useEffect, useState, useCallback, memo } from 'react';
import {Linking, StyleSheet,View } from 'react-native'
import FlatButton from '../../components/jackpot/FlatButton'
import KeypadNumber from '../../components/jackpot/KeypadNumber'
import LEDDisplay from '../../components/jackpot/LEDDisplay'
import RouletteWheel from '../../components/jackpot/RouletteWheel'
import WinningMultiplier from '../../components/jackpot/WinningMultiplier'
import colors from '../../strings/colors'
import { StatusBar,Platform,SafeAreaView ,Dimensions, Animated} from 'react-native';
import {  
  useGetBalanceQuery,
  usePlayMutation, 
  useGetSettingsQuery
} from '../../redux/api';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/core';
import { KEYS_10K,
  SPIN_ONCE,
  DECELERATE_MAP,
  SPIN_TO_MAP,
  MULTIPLIER_MAP,
  SPIN_MAP,
  CONSTANT_SPEED,
  COLOR_MAP
} from './game/constants'
import { getFiveDigitsAmount } from './game/helpers'
import { playBeep, playSpinSound, playWin100OrHigher, playWin10OrHigher, playWin50OrHigher } from './game/sounds';
import Toast from 'react-native-toast-message';
import { colorActions } from '../../redux/colorSlice';
import { numbersSliceActions } from '../../redux/numbersSlice';
import { multipliersSliceActions } from '../../redux/multipliersSlice';

function Jackpot() {

  const user_state = useSelector(state => state.user);

  const sound_state = useSelector(state => state.sound);

  const color_state = useSelector(state => state.color);

  const dispatch = useDispatch();

  const [play, playResult] = usePlayMutation();

  const {data:balance_data,isLoading:balance_isLoading, isSuccess: loadingBalanceSuccessfull,refetch:refetchBalance} = useGetBalanceQuery(user_state._id);

  const {data:settings_data, isLoading: settings_isLoading, isSuccess: loadingSettingsSucceful, refetch:refetchSettings} = useGetSettingsQuery(user_state._id);

  const [isPlaying, setIsPlaying] = useState(false);

  const navigation = useNavigation();

  
  StatusBar.setBarStyle('light-content'); // set the status bar text color to light
  if (Platform.OS === 'ios') {
    StatusBar.setBarStyle('dark-content'); // set the status bar text color to dark
    
  } else if (Platform.OS === 'web') {
   
    StatusBar.setBarStyle('light-content'); // set the status bar text color to light
   
  }
  
  StatusBar.setHidden(true);
  

  const [keys_default,setKeysDefault] = useState(KEYS_10K);

  const [keypad_numbers,setKeypadNumbers] = useState([
    {number:0, played:false, keys:[...keys_default.keys]},
    {number:1, played:false, keys:[...keys_default.keys]},
    {number:2, played:false, keys:[...keys_default.keys]},
    {number:3, played:false, keys:[...keys_default.keys]},
    {number:4, played:false, keys:[...keys_default.keys]},
    {number:5, played:false, keys:[...keys_default.keys]},
    {number:6, played:false, keys:[...keys_default.keys]},
    {number:7, played:false, keys:[...keys_default.keys]},
    {number:8, played:false, keys:[...keys_default.keys]},
    {number:9, played:false, keys:[...keys_default.keys]},
    {number:10, played:false, keys:[...keys_default.keys]},
    {number:11, played:false, keys:[...keys_default.keys]},
    {number:12, played:false, keys:[...keys_default.keys]}
  ]);

  //winning multipliers number array

  const [winning_multipliers,setWinningMultipliers] = useState([
    {number:10,isOn:false, width:'15%'},
    {number:50,isOn:false, width:'15%'},
    {number:1000,isOn:false, width:'25%'},
    {number:25,isOn:false, width:'15%'},
    {number:100,isOn:false, width:'20%'},
  ]);


  const [prevIndex, setPrevIndex] = useState(0);

  //credit and win amount

  const [credit, setCredit] = useState(0);
  const [winAmount, setWinAmount] = useState(0);

  

  const playSound = (name) => {
    if(sound_state.isSoundOn){
      switch(name){
        case 'spin':
          playSpinSound();
          break;
        case 'beep':
          playBeep();
          break;
        case 'win':
          playWin10OrHigher();
          break;
        case 'win_50':
          playWin50OrHigher();
          break;
        case 'win_100':
            playWin100OrHigher();
        default:
          break;
      }
    }
  }


  const getNumbersPlayed = useCallback(() => {
    const results = [];
    keypad_numbers.forEach(item => {
      const sumForKey = item.keys.reduce((accum, key) => key.isOn ? accum + key.number : accum, 0);
      if (sumForKey !== 0) {
        results.push({
          number: item.number,
          times: sumForKey
        });
      }
    });
    return results;
  }, [keypad_numbers]);

  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  const handleStart = useCallback(async () => {
    const numbersPlayed = getNumbersPlayed();
    if (numbersPlayed.length === 0) {
      Toast.show({
        type: 'error',
        text1: 'Place Your Bet',
        text2: 'Please select at least one number to play',
      })
      return;
    }
    if (credit < 1) {
      Toast.show({
        type: 'error',
        text1: 'Insufficient Credit',
        text2: 'You do not have enough credit to play',
      })
      return;
    }
    if (isPlaying) {
      Toast.show({
        type: 'error',
        text1: 'Spin in Progress',
        text2: 'Please wait for the current spin to finish',
      })
      return;
    }
    setIsPlaying(true);
    const playData = {
      userId: user_state._id,
      numbersPlayed,
      location: 'namibia',
    };
    let totalPlayedAmount = 0;
      numbersPlayed.forEach(item => {
      totalPlayedAmount += item.times;
    });
    if (totalPlayedAmount > credit) {
      Toast.show({
        type: 'error',
        text1: 'Insufficient Credit',
        text2: 'You do not have enough credit to play',
      })
      setIsPlaying(false);
      return;
    }
    setCredit(credit - totalPlayedAmount);
    play(playData);
  }, [user_state._id, credit, isPlaying, getNumbersPlayed]);
  

  const shuffleMultipliers = useCallback(async (winning_multiplier) => {
    
    let shuffle_indexes = MULTIPLIER_MAP[winning_multiplier];
    
    for (let i = 0; i < shuffle_indexes.length; i++) {
        // Reset all multipliers to isOn: false
        dispatch(multipliersSliceActions.resetMultipliers());

        // Set the specific multiplier to isOn: true
        dispatch(multipliersSliceActions.setOn(shuffle_indexes[i]));

        await delay(80); // Assuming delay is a function that returns a promise after a timeout
    }
}, []);

  //get spin from array
  const spinFromArray = () => {
    return SPIN_MAP[prevIndex];
  }

  

 
const playSpin = useCallback(async (array, deceleration) => {
  

  for (let i = 0; i < array.length; i++) {
    dispatch(numbersSliceActions.resetNumbers()); // reset all numbers to isOn: false

    dispatch(numbersSliceActions.setOn(array[i])); // set the specific number to isOn: true

    let current_deceleration = 0;
    if (deceleration > 0) {
      current_deceleration = CONSTANT_SPEED + i * CONSTANT_SPEED;
    }

    await new Promise((resolve) => setTimeout(resolve, CONSTANT_SPEED + current_deceleration));
  }
}, []);

const spin2 = useCallback(async (winningNumber) => {
  const fromArray = spinFromArray();
  await playSpin(fromArray, 0);
  for (let i = 0; i < 2; i++) {
    await playSpin(SPIN_ONCE, 0);
  }
  await playSpin(SPIN_TO_MAP[winningNumber], 0);
  await playSpin(DECELERATE_MAP[winningNumber], 30);
  setPrevIndex(winningNumber);
}, [prevIndex, playSpin]);

  const turnAllKeysOff = useCallback(() => {
    let copyKeypadNumbers = [...keypad_numbers];
    for (let i = 0; i < copyKeypadNumbers.length; i++) {
      let newKeys = copyKeypadNumbers[i].keys.map((key) => {
        return {
          ...key,
          isOn: false
        };
      });
      copyKeypadNumbers[i].keys = newKeys;
    }
    setKeypadNumbers(copyKeypadNumbers);
  }, [keypad_numbers]);



  const handleCancel = async () => {
    playSound('beep');
    turnAllKeysOff();
    
  };

  const handlePayin = () => {
    navigation.navigate('Payin');
  };

  const handlePayout = () => {
    navigation.navigate('Payout');
  };

  const onKeyPress = (keyPadNumber,keyClicked) => {
     playSound('beep');
     let copyKeypadNumbers = [...keypad_numbers];

     for(let i = 0; i < copyKeypadNumbers.length; i++){
        if(copyKeypadNumbers[i].number === keyPadNumber){
           let newKeys= copyKeypadNumbers[i].keys.map((key) => {
             if(key.number === keyClicked){
               return {
                 ...key,
                 isOn: !key.isOn
               }
               
             }
             else{
              return key;
            }
           });

           copyKeypadNumbers[i].keys = newKeys;
        }
     }
     setKeypadNumbers(copyKeypadNumbers);
  };

  //animate set credit by incrementing by 1 and decrementing win amount by 1

  const animateCredit = useCallback(async (total_winning_amount) => {
    setWinAmount(total_winning_amount);
    await delay(500);
    setWinAmount(0);
    await delay(500);
    setWinAmount(total_winning_amount);
    await delay(500);
    setWinAmount(0);
    await delay(500);
    setWinAmount(total_winning_amount);
    await delay(500);
    setWinAmount(0);
    await delay(500);
    setWinAmount(total_winning_amount);
    let credit_copy = credit;
    let win_amount_copy = total_winning_amount;
    while(credit_copy < credit + total_winning_amount){
      credit_copy++;
      win_amount_copy--;
      setCredit(credit_copy);
      setWinAmount(win_amount_copy);
      await delay(20);
    }
  }, [credit, winAmount]);

  useEffect(() => {
    refetchBalance();
    refetchSettings();
  }, [refetchBalance, refetchSettings]);

  useEffect(() => {
      if(loadingBalanceSuccessfull) {
        setCredit(balance_data.balance);
      }
      if(loadingSettingsSucceful) {
         dispatch(colorActions.setColor(settings_data.background))
      }
  }, [balance_isLoading, settings_isLoading]);

 

  useEffect(() => {
  
    const spinWheel = async () => {
      if (!playResult.data) return;

      playSound('spin');

      await spin2(playResult.data.number);

      if(playResult.data.won===true){
        const total_winning_amount = playResult.data.winning_amount * playResult.data.times;

        if(total_winning_amount >= 100){
          playSound('win_100');
        }else if(total_winning_amount >= 50){
          playSound('win_50');
        }else{
          playSound('win');
        }
        shuffleMultipliers(playResult.data.winning_amount);
        await delay(1280);
        await delay(1280);
       await animateCredit(total_winning_amount);
      }else{
        //playSound('beep');
        setCredit(playResult.data.new_balance);
      }

      setIsPlaying(false);

    };
  
    spinWheel();
  
  }, [playResult.isLoading]);
  
  return (
    <SafeAreaView style={{backgroundColor:colors.gray }}>
     <View style={[styles.container,{backgroundColor:COLOR_MAP[color_state.value]['light']}]}>
         <View style={[styles.upperContainer,{backgroundColor:COLOR_MAP[color_state.value]['dark']}]}>
          <View style={styles.leftContainer}>
               <FlatButton color={'yellow'} title="PAYIN" onPress={() =>  handlePayin()} />
               <FlatButton color={'red'} title="PAYOUT" onPress={() =>  handlePayout()} />
          </View>
          <View style={styles.centerContainer}>
            <RouletteWheel isLoading={playResult.isLoading}/>
          </View>
          <View style={styles.rightContainer}>
            <FlatButton color={'red'} title="CANCEL" onPress={() =>  handleCancel()} />
            <FlatButton color={'green'} title="START" onPress={() =>  handleStart()} />
          </View> 
        </View>
        <View style={styles.displays}>
            <View style={[styles.leftContainer,{marginLeft:1}]}>
                <LEDDisplay name={'WIN'} amount={getFiveDigitsAmount(winAmount)}/>
            </View>
            <View style={[styles.centerContainer,{width:200}]}>
               <WinningMultiplier/>
            </View>
            <View style={styles.rightContainer}>
            <LEDDisplay name={'CREDIT'} amount={getFiveDigitsAmount(credit)}/>
            </View>
        </View>
        <View style={[styles.key_pad, {backgroundColor:COLOR_MAP[color_state.value]['dark']}]}>
          {/** 1st row */}
          <View style={styles.keyPadRow}>
            <View style={styles.keyPadNumber}>
              
            </View>
            <View style={styles.keyPadNumber}>
            <KeypadNumber number={keypad_numbers[0]} backgroundColor={colors.green} onPress={onKeyPress}/>
            </View>
            <View style={styles.keyPadNumber}>
              
            </View>
          </View>
          {/** 2nd row */}
          <View style={styles.keyPadRow}>
            <View style={styles.keyPadNumber}>
              <KeypadNumber number={keypad_numbers[1]} backgroundColor={colors.red} onPress={onKeyPress}/>
            </View>
            <View style={styles.keyPadNumber}>
              <KeypadNumber number={keypad_numbers[2]} backgroundColor={colors.black} onPress={onKeyPress}/>
            </View>
            <View style={styles.keyPadNumber}>
              <KeypadNumber number={keypad_numbers[3]} backgroundColor={colors.red} onPress={onKeyPress}/>
            </View>
          </View>
          {/** 3rd row */}
          <View style={styles.keyPadRow}>
            <View style={styles.keyPadNumber}>
              <KeypadNumber number={keypad_numbers[4]} backgroundColor={colors.black} onPress={onKeyPress}/>
            </View>
            <View style={styles.keyPadNumber}>
              <KeypadNumber number={keypad_numbers[5]} backgroundColor={colors.red} onPress={onKeyPress}/>
            </View>
            <View style={styles.keyPadNumber}>
              <KeypadNumber number={keypad_numbers[6]} backgroundColor={colors.black} onPress={onKeyPress}/>
            </View>
          </View>
          {/** 4th row */}
          <View style={styles.keyPadRow}>
            <View style={styles.keyPadNumber}>
              <KeypadNumber number={keypad_numbers[7]} backgroundColor={colors.red} onPress={onKeyPress}/>
            </View>
            <View style={styles.keyPadNumber}>
              <KeypadNumber number={keypad_numbers[8]} backgroundColor={colors.black} onPress={onKeyPress}/>
            </View>
            <View style={styles.keyPadNumber}>
              <KeypadNumber number={keypad_numbers[9]} backgroundColor={colors.red} onPress={onKeyPress}/>
            </View>
          </View>
          {/** 5th row */}
          <View style={styles.keyPadRow}>
            <View style={styles.keyPadNumber}>
              <KeypadNumber number={keypad_numbers[10]} backgroundColor={colors.black} onPress={onKeyPress}/>
            </View>
            <View style={styles.keyPadNumber}>
              <KeypadNumber number={keypad_numbers[11]} backgroundColor={colors.red} onPress={onKeyPress}/>
            </View>
            <View style={styles.keyPadNumber}>
              <KeypadNumber number={keypad_numbers[12]} backgroundColor={colors.black} onPress={onKeyPress}/>
            </View>
          </View>
        </View>
       
       
    </View>
  </SafeAreaView>
   
  )
}

const styles = StyleSheet.create({
  container: {
    
    backgroundColor:colors.green, 
    height: Platform.OS === 'web' ? 700 : '100%',
    width: Dimensions.get('window').width>1200? 400 : '100%',
    alignItems:'center',
    justifyContent:'flex-start',
    padding: 2,
    margin: 'auto',
    flexDirection:'column',
  },
  upperContainer: {
    
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection:'row',
    marginTop: 10,
    height: 180,
    borderRadius: 10,
    width:'98%',
    margin:'auto',
    padding:10,
    backgroundColor:colors.light_blue
  },
  displays: {
    
    flexDirection:'row',
    marginTop: 2,
    borderRadius: 10,
    justifyContent:'center',
    alignItems:'center',
    width:'98%',
    height: 100,
    margin: 'auto',
    padding:0,
    
  },
  leftContainer: {
    flex:1,
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection:'column',
  },
  rightContainer: {
    flex:1,
    alignItems: 'flex-end',
    justifyContent: 'center',
    flexDirection:'column',
    
  },
  centerContainer: {
    flex:2,
    justifyContent: 'center',
    flexDirection:'column',
    
  },
  key_pad: {
    flex:2,
    justifyContent: 'center',
    flexDirection:'column',
    alignItems:'center',
    width:'90%',
    height: '100%',
    backgroundColor:colors.light_blue,
    borderRadius: 10,
    marginTop: 1,
    padding:10,
  },
  keyPadRow: {
    flex:1,
    flexDirection:'row',
    justifyContent:'center',
    width:'100%',
    flexDirection:'row',
  },
  keyPadNumber: {
    flex:1,
    justifyContent:'center',
    alignItems:'center',
  }
  
})

export default memo(Jackpot);