import React, { useEffect, useState } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import colors from '../../strings/colors';
import { useDispatch } from 'react-redux';
import { soundActions } from '../../redux/soundSlice';

const SoundToggle = () => {
  const [muted, setMuted] = useState(false);

  const dispatch = useDispatch();

  const handlePress = () => {
    setMuted((prevState) => !prevState);
  };

  useEffect(() => {
    dispatch(soundActions.toggleSound());
  }, [muted]);

  return (
    <View>
      <TouchableOpacity style={{marginRight:10}} onPress={handlePress}>
        {muted ? (
          <Ionicons name="volume-mute-outline" size={33} color={colors.white} />
        ) : (
          <Ionicons name="ios-volume-high-outline" size={33} color={colors.white} />
        )}
      </TouchableOpacity>
    </View>
  );
};

export default SoundToggle;
