/**
 * soundSlice.js
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2023
 * All rights reserved
 */

import { createSlice } from '@reduxjs/toolkit';

export const soundSlice = createSlice({
    name: 'sound',
    initialState: {
        isSoundOn: false
    },
    reducers: {
        toggleSound: (state) => {
            state.isSoundOn = !state.isSoundOn;
        }
    }
});

export const soundActions = soundSlice.actions;