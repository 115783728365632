import React from 'react';
import { Text, StyleSheet,  } from 'react-native';

function SpinWheelNumber({ number, color, size, angle, isOn, marginTop, marginLeft }) {
  return (
    <Text 
      style={[
        {
          color: color, // Use animated color here
          fontSize: size,
          fontFamily: 'Gloucester',
          position: 'absolute',
          transform: [{ rotate: `${angle}deg` }],
          marginTop,
          marginLeft,
        }, 
        isOn && styles.on
      ]}
    >
      {number}
    </Text>
  );
}

const styles = StyleSheet.create({
  on: {
    fontFamily: 'Gloucester',
    fontSize: 17,
    color: '#fff',
    fontWeight: 'bold',
    textShadowColor: '#fff',
    textShadowOffset: { width: 0, height: 0 },
    textShadowRadius: 8,
  },
});

export default React.memo(SpinWheelNumber);

