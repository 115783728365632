import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { Picker } from '@react-native-picker/picker';
import RoundedInput from '../../components/common/RoundedInput';
import RoundedButton from '../../components/common/RoundedButton';
import colors from '../../strings/colors';
import { useUpdateSettingsMutation } from '../../redux/api';
import Loader from '../../components/common/Loader';
import SiskusIcon from '../../components/common/SiskusIcon';
import Toast from 'react-native-toast-message';
import { useSelector, useDispatch } from 'react-redux';
import { colorActions } from '../../redux/colorSlice';

const Settings = () => {

  const user_state  = useSelector(state => state.user);

  const color_state = useSelector(state => state.color);

  const dispatch = useDispatch();

  const [background, setBackground] = useState('blue');
  const [depositLimit, setDepositLimit] = useState('unlimited');
  const [timeLimit, setTimeLimit] = useState('unlimited');
  const [status, setStatus] = useState('');

  const [updateSettings, updateSettingsResult] = useUpdateSettingsMutation();

  const handleDepositLimitChange = text => {
    setDepositLimit(text);
  };

  const handleTimeLimitChange = text => {
    setTimeLimit(text);
  };

  const handleUpdate = () => {
    // Add validation logic here if needed

    updateSettings({
      userId: user_state._id,
      background: background,
      depositLimit: depositLimit,
      timeLimit: timeLimit,
    });
  };

  useEffect(() => {
    if (updateSettingsResult.isSuccess) {
       Toast.show({
        type:'success',
        text1:'Success',
        text2:'Settings updated successfully'
      });
      dispatch(colorActions.setColor(updateSettingsResult.data.background))
    }
    if (updateSettingsResult.isError) {
      Toast.show({
        type:'error',
        text1:'Error',
        text2:updateSettingsResult.error.message
      });
      console.log(updateSettingsResult.error);
    }
  }, [updateSettingsResult.isLoading]);

  if (updateSettingsResult.isLoading) {
    return (
      <Loader/>
    );
  }

  return (
    <View style={styles.container}>
      <View style={styles.layout}>
        <SiskusIcon title={'Settings'} description={'Update Your Settings'} />
        <Text style={styles.label}>Background Color</Text>
        <Picker
          selectedValue={background}
          onValueChange={(itemValue) => setBackground(itemValue)}
          style={styles.picker}
        >
          <Picker.Item label="Blue" value="blue" />
          <Picker.Item label="Pink" value="pink" />
          <Picker.Item label="Green" value="green" />
        </Picker>
        <Text style={styles.statusText}>{status}</Text>
        <RoundedButton title="UPDATE SETTINGS" onPress={handleUpdate} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
    justifyContent: 'center',
    alignItems: 'center',
  },
  layout: {
    width: '90%',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 20,
  },
  statusText: {
    color: colors.orange,
    marginTop: 10,
    marginBottom: 10,
    textAlign: 'center',
  },
  picker: {
    fontFamily: 'Poppins-Bold',
    borderRadius: 25,
    backgroundColor: 'lightgray',
    paddingHorizontal: 16,
    flex: 1,
    padding: 12,
    fontSize: 16,
    borderColor: 'transparent',
    borderWidth: 0,
    marginBottom: 15,
  },
  label: {
    fontFamily: 'Poppins-Bold',
    fontSize: 16,
    marginLeft: 10,
    marginBottom: 8,
  },
});

export default Settings;
