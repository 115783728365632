/**
 * colorSlice.js
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2023
 * All rights reserved
 */

import { createSlice } from '@reduxjs/toolkit';

export const colorSlice = createSlice({
    name: 'color',
    initialState: {
        value: 'blue',
    },
    reducers: {
        setColor: (state, action) => {
        state.value = action.payload;
        },
    },
});

export const colorActions = colorSlice.actions;