import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import colors from '../../strings/colors';
import SiskusIcon from '../../components/common/SiskusIcon';
import RoundedButton from '../../components/common/RoundedButton';
import RoundedInput from '../../components/common/RoundedInput';
import Toast from 'react-native-toast-message';
import { urls } from '../../strings/urls';
import {  
    useGetBalanceQuery,
    usePayoutMutation
} from '../../redux/api';
import { useSelector } from 'react-redux';
import Loader from '../../components/common/Loader';


// Define Payout Steps
const PayoutSteps = {
  SELECT_OPTION: 'SELECT OPTION',
  BANK_TRANSFER: 'BANK TRANSFER',
  E_WALLET: 'E-WALLET',
};

const PAYOUT_CHARGES = 5;

const Payout = () => {

  const user_state = useSelector(state => state.user);

  const [currentStep, setCurrentStep] = useState(PayoutSteps.SELECT_OPTION);
  const [payoutOptions, setPayoutOptions] = useState([]); // Initialize with empty array
  const [selectedPayoutOption, setSelectedPayoutOption] = useState(null);
  const [recipientPhoneNumber, setRecipientPhoneNumber] = useState(user_state.cellphone);
  const [accountName, setAccountName] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [accountType, setAccountType] = useState('');
  const [bankName, setBankName] = useState('');
  const [branch, setBranch] = useState('');
  const [status, setStatus] = useState('');
  const [statusColor, setStatusColor] = useState(colors.orange);
  const [amount, setAmount] = useState(0);

  const [maxPayout, setMaxPayout] = useState(0);

  

    const {data:balance_data,isLoading:balance_isLoading, isSuccess: loadingBalanceSuccessfull, refetch} = useGetBalanceQuery(user_state._id);

    const [payout, payoutResult] = usePayoutMutation();

  useEffect(() => {
    // Fetch payout options from the API and set them in state
    // Replace the fetch logic with your actual API call
    const fetchPayoutOptions = async () => {
      try {
        const response = await fetch(urls.BASE_URL + '/pos');
        const data = await response.json();
        setPayoutOptions(data);
      } catch (error) {
        Toast.show({
            type: 'error',
            text1: 'Error',
            text2: 'An error occurred while fetching payout options.',
        });
      }
    };

    fetchPayoutOptions();
  }, []);

  const handleSelectOption = (option) => {

    //validate amount

    if(amount === 0){
        setStatusColor(colors.red);
        Toast.show({
            type:'error',
            text1:'Validation Error',
            text2:'Please specify an amount to payout.'
        });

        return;
    } else if (amount<50){
        setStatusColor(colors.red);
        Toast.show({
            type:'error',
            text1:'Validation Error',
            text2:'Minimum payout amount is N$50.'
        });

        return;
    } else if (amount > maxPayout){
        setStatusColor(colors.red);
        Toast.show({
            type:'error',
            text1:'Validation Error',
            text2:`Maximum payout amount is N$${maxPayout}.`
        });

        return;
    }

    setSelectedPayoutOption(option);
    setCurrentStep(
      option.name === 'Bank Transfare'
        ? PayoutSteps.BANK_TRANSFER
        : PayoutSteps.E_WALLET
    );
  };

  const handleNextStep = () => {

    

    if (currentStep === PayoutSteps.BANK_TRANSFER) {
      // Validate and process bank transfer form data
      if (
        !accountName ||
        !accountNumber ||
        !accountType ||
        !bankName ||
        !branch
      ) {
        setStatusColor(colors.red);
        Toast.show({
          type: 'error',
          text1: 'Validation Error',
          text2: 'Please fill in all required fields for bank transfer.',
        });
        return;
      }
      // Process bank transfer logic here
      // ...
    } else if (currentStep === PayoutSteps.E_WALLET) {
      // Validate and process E-wallet recipient phone number
      if (!recipientPhoneNumber) {
        setStatusColor(colors.red);
        Toast.show({
          type: 'error',
          text1: 'Validation Error',
          text2: 'Please enter the recipient phone number for E-wallet.',
        });
        return;
      }
      // Process E-wallet logic here
      // ...
    }

    payout(
        {
            userId: user_state._id,

            cellphone: recipientPhoneNumber,

            amount: parseInt(amount),

            prefaredMethod: selectedPayoutOption._id,

            bankDetails: JSON.stringify({
                accountName: accountName,
                accountNumber: accountNumber,
                accountType: accountType,
                bankName: bankName,
                branch: branch
            })
        }
    )

  };

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if(loadingBalanceSuccessfull){
        setMaxPayout(balance_data.balance - PAYOUT_CHARGES);
        setRecipientPhoneNumber(user_state.cellphone);
        setAmount(balance_data.balance - PAYOUT_CHARGES);
    }
    
  }, [balance_isLoading]);

  useEffect(() => {
    if (payoutResult.isSuccess) {
        if (payoutResult.data.success === true){
            Toast.show({
                type: 'success',
                text1: 'Success',
                text2: payoutResult.data.message,
            });
        } else {
            Toast.show({
                type: 'error',
                text1: 'Error',
                text2: payoutResult.data.message,
            });
        }
    }
    if (payoutResult.isError) {
        Toast.show({
            type: 'error',
            text1: 'Error',
            text2: 'An error occurred while processing your payout.',
        });
    }
  }, [payoutResult.isLoading]);

  if (balance_isLoading || payoutResult.isLoading) {
    return <Loader/>;
  }

  return (
    <View style={styles.container}>
      {currentStep === PayoutSteps.BANK_TRANSFER && <SiskusIcon description={`${currentStep}`} title={`Amount: N$ ${amount}`}/>}
      {currentStep === PayoutSteps.E_WALLET && <SiskusIcon description={`Send ${currentStep} to this number`} title={`Amount: N$${amount}`} />}
      {currentStep === PayoutSteps.SELECT_OPTION && <SiskusIcon description={`Minimum Payout: N$50 | Charges: N$5`}  title={`Max Payout: N$${maxPayout}`} />}

      {currentStep === PayoutSteps.SELECT_OPTION && (
        <>
          <RoundedInput
            placeholder="Amount"
            onChangeText={setAmount}
            keyboardType='numeric'
            value={amount}
          />
          {payoutOptions.map((option) => (
            <RoundedButton
              key={option._id}
              title={option.name}
              onPress={() => handleSelectOption(option)}
            />
          ))}
        </>
      )}

      {currentStep === PayoutSteps.BANK_TRANSFER && (
        <>
          <RoundedInput
            placeholder="Account Name"
            onChangeText={setAccountName}
            value={accountName}
          />
          <RoundedInput
            placeholder="Account Number"
            onChangeText={setAccountNumber}
            value={accountNumber}
          />
          <RoundedInput
            placeholder="Account Type"
            onChangeText={setAccountType}
            value={accountType}
          />
          <RoundedInput
            placeholder="Bank Name"
            onChangeText={setBankName}
            value={bankName}
          />
          <RoundedInput
            placeholder="Branch"
            onChangeText={setBranch}
            value={branch}
          />
        </>
      )}

      {currentStep === PayoutSteps.E_WALLET && (
        <>
          <RoundedInput
            placeholder="Recipient Phone Number"
            onChangeText={setRecipientPhoneNumber}
            value={recipientPhoneNumber}
            keyboardType="phone-pad"
          />
        </>
      )}

      {currentStep !== PayoutSteps.SELECT_OPTION && <RoundedButton title="PAYOUT" onPress={handleNextStep} />}

      <Text style={[styles.statusText, { color: statusColor }]}>{status}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  statusText: {
    marginTop: 20,
    textAlign: 'center',
  },
});

export default Payout;
