import RouletteWheel from "../../../../components/jackpot/RouletteWheel";
import SpinWheelNumber from "../../../../components/jackpot/SpinWheelNumber";
import colors from "../../../../strings/colors";
import { DECELERATE_MAP, SPIN_MAP } from "../constants";

const SpinnerEntity = {
    state: 'idle',  // Initial state set to 'idle'n
    spinPhase: null,  // Initially no spin phase
    spinCount: 0,  // Initial spin count is zero
    currentIndex: 0,  // Initial index for tracking the current number in the spin sequence
    currentNumber: 0,  // Initial number being shown
    winningNumber: null,  // No winning number to start
    lastUpdated: null,  // Timestamp of the last update
    deceleration: 0,  // Initial deceleration is zero
    i: 0,  // Loop index for deceleration
    startNumber: 0,  // The starting number for the spin
    // An array of numbers for the visual representation
    numbers: [
        { number: 0, color: colors.light_gray, size: 16, angle:2, isOn: true, marginTop: 12, marginLeft: 72.8},
        { number: 5, color: colors.light_gray, size: 16, angle: 30, isOn: false, marginTop: 19, marginLeft: 94},
        { number: 12, color: colors.light_gray, size: 16, angle: 57, isOn: false, marginTop: 35, marginLeft: 109},
        { number: 3, color: colors.light_gray, size: 16, angle: 85, isOn: false, marginTop:59, marginLeft: 120},
        { number: 10, color: colors.light_gray, size: 16, angle: 113, isOn: false, marginTop: 83, marginLeft: 112},
        { number: 1, color: colors.light_gray, size: 16, angle: 140, isOn: false, marginTop: 101, marginLeft: 101},
        { number: 8, color: colors.light_gray, size: 16, angle: 168, isOn: false, marginTop:111, marginLeft: 79.5},
        { number: 9, color: colors.light_gray, size: 16, angle: 196, isOn: false, marginTop: 109.5, marginLeft: 55},
        { number: 2, color: colors.light_gray, size: 16, angle: 225, isOn: false, marginTop: 96, marginLeft: 34.8},
        { number: 7, color: colors.light_gray, size: 16, angle: 252, isOn: false, marginTop: 77, marginLeft: 22.5},
        { number: 6, color: colors.light_gray, size: 16, angle: 280, isOn: false, marginTop: 52.9, marginLeft: 21.5},
        { number: 11, color: colors.light_gray, size: 16, angle: 308, isOn: false, marginTop: 31.8, marginLeft: 27},
        { number: 4, color: colors.light_gray, size: 16, angle: 336, isOn: false, marginTop: 17, marginLeft: 49},
    ],

    renderer: <RouletteWheel />,

  };
  
  export default SpinnerEntity;
  