/**
 * multiplier.system.js
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2023
 * All rights reserved
 */

export const MultiplierSystem = (entities, { time }) => {

  const multiplier = entities.multiplier;
  
  if (!multiplier.lastUpdated) {
    multiplier.lastUpdated = time.current;
  }

  const deltaTime = time.current - multiplier.lastUpdated;
  
  if (deltaTime >= 80 && multiplier.shuffleIndexes.length > 0) {
    const nextIndex = multiplier.shuffleIndexes.shift(); // remove the first index
    
    multiplier.winning_multipliers = multiplier.winning_multipliers.map((mul, index) => {
      return {
        ...mul,
        isOn: index === nextIndex,
      };
    });

    multiplier.lastUpdated = time.current;
  }

  return entities;
};
