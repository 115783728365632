import React, { useEffect, useState } from 'react';
import { StyleSheet, View, TextInput, TouchableOpacity, Text } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import colors from '../../strings/colors';

const RoundedInput = ({ placeholder, onChangeText, onPress, isPassword = false, keyboardType = 'default', value }) => {
  const [isPasswordVisible, setPasswordVisibility] = useState(false);

  return (
    <View style={styles.inputContainer}>
      <TextInput
        style={styles.input}
        placeholder={placeholder}
        onChangeText={onChangeText}
        placeholderTextColor="gray"
        value={value}
        underlineColorAndroid="transparent"
        secureTextEntry={isPassword && !isPasswordVisible}
        keyboardType={keyboardType}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  inputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 25,
    backgroundColor: 'lightgray',
    width: '100%',
    borderColor: '#333',
    marginBottom: 15,
  },
  input: {
    fontFamily: 'Poppins-Bold',
    borderRadius: 25,
    backgroundColor: 'lightgray',
    paddingHorizontal: 16,
    flex: 1,
    padding: 12,
    fontSize: 16,
    borderColor: 'transparent',
    borderWidth: 0,
  },
  
});

export default RoundedInput;
