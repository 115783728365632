
import React, { useState } from 'react'

import { createStackNavigator } from '@react-navigation/stack';
import Login from '../screens/Auth/Login';
import Register from '../screens/Auth/Register';
import Jackpot from '../screens/Main/Jackpot';
import Profile from '../screens/Main/Profile';
import { Image } from 'react-native';
import colors from '../strings/colors';
import MenuIcon from '../components/jackpot/MenuIcon';
import SoundToggle from '../components/jackpot/SoundToggle';
import NotificationBell from '../components/jackpot/NotificationBell';
import { View } from 'react-native';
import InternetConnectionStatusIcon from '../components/jackpot/InternetConnectionStatusIcon';
import MenuPopup from '../components/jackpot/MenuPopup';
import PhoneSearch from '../screens/Auth/PhoneSearch';
import SendCredit from '../screens/Payments/SendCredit';
import Payin from '../screens/Payments/Payin';
import Payout from '../screens/Payments/Payout';
import Game from '../screens/Main/game/Game';
import ChangePassword from '../screens/Auth/ChangePassword';
import Settings from '../screens/Main/Settings';
import MyWebView from '../screens/Main/MyWebView';
import WhatsApp from '../components/common/WhatsApp';

const logo = {uri:'https://siskus.com/images/logo2.png'}


const Stack = createStackNavigator();

function Navigator() {


  const [isMenuVisible,setIsMenuVisible] = useState(false);

  const [icon,setIcon] = useState('menu-outline');

  const handleMenuClose = () => {
     setIsMenuVisible(false);
     setIcon('menu-outline');
  }

  const  handleMenuOpen = () => {
    //Vibration.vibrate(50);
    setIsMenuVisible(true);
    setIcon('close')
  }

  return (
    <Stack.Navigator initialRouteName='Search' >

        {/**Auth Routes */}

        <Stack.Screen name="Login" options={{ headerShown: false }}  component={Login} />
        <Stack.Screen 
          name="Register" 
          component={Register} 
          options={{
            headerTitle: "",
          }}
        />
        <Stack.Screen name="Search" options={{ headerShown: false }} component={PhoneSearch} />

        {/**Main Routes */}

        <Stack.Screen
         name="Jackpot" 
         component={Jackpot} 
         options={{
          headerTitle: () => (
              <Image
                style={{
                   width: 130, 
                   height: 40,
                   marginBottom:10,
                
                  }}
                source={require('../../assets/images/siskus.png')}
                resizeMode="contain"
              />
            ),
            
          headerRight: () => (
            <View
              style={{
                flexDirection: 'row'
              }}
            >
               <WhatsApp number="+264818210855" message="👋Hey there! I found your number on the Siskus app!" />
               <SoundToggle/>
            </View>
           
          ),

          headerLeft: ()=>(
            
            <View
              style={{
                flexDirection: 'row'
              }}
            >
              <MenuIcon onPress={handleMenuOpen} icon={icon} />
              <InternetConnectionStatusIcon />
              <MenuPopup visible={isMenuVisible} close={handleMenuClose}/>
            </View>
          ),

          headerTitleAlign: 'center',

          headerStyle: {
             
              backgroundColor: colors.gray,
              shadowColor: '#fff',
          },
         
        }}

         />
        

        <Stack.Screen name="Game"
         component={Game}
         options={{
          headerTitle: () => (
              <Image
                style={{
                   width: 130, 
                   height: 40,
                   marginBottom:10,
                
                  }}
                source={logo}
                resizeMode="contain"
              />
            ),
            
          headerRight: () => (
            <View
              style={{
                flexDirection: 'row'
              }}
            >
               <NotificationBell  count={5} />
               <SoundToggle/>
            </View>
           
          ),

          headerLeft: ()=>(
            
            <View
              style={{
                flexDirection: 'row'
              }}
            >
              <MenuIcon onPress={handleMenuOpen} icon={icon} />
              <InternetConnectionStatusIcon />
              <MenuPopup visible={isMenuVisible} close={handleMenuClose}/>
            </View>
          ),

          headerTitleAlign: 'center',

          headerStyle: {
             
              backgroundColor: colors.gray,
              shadowColor: '#fff',
          },
         
        }}
          />

        <Stack.Screen 
          name="SendCredit" 
          component={SendCredit} 
          options={{
            headerTitle: "",
          }}
        />

        <Stack.Screen 
          name="Payin" 
          component={Payin} 
          options={{
            headerTitle: "",
          }}
        />

         <Stack.Screen 
          name="Payout" 
          component={Payout} 
          options={{
            headerTitle: "",
          }}
        />

        <Stack.Screen 
          name="Profile" 
          component={Profile} 
          options={{
            headerTitle: "",
          }}
        />

        <Stack.Screen 
          name="Settings" 
          component={Settings} 
          options={{
            headerTitle: "",
          }}
        />

        <Stack.Screen 
          name="ChangePassword" 
          component={ChangePassword} 
          options={{
            headerTitle: "",
          }}
        />

        <Stack.Screen 
          name="MyWebView" 
          component={MyWebView} 
          options={{
            headerTitle: "",
          }}
        />

    </Stack.Navigator>
  )
}

export default Navigator