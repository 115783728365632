/**
 * game.sounds.js
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2023
 * All rights reserved
 */

import { Audio as Av } from 'expo-av';
import { Platform } from 'react-native';

import {
    BEEP,
    SPIN,
    WIN_100_OR_HIGHER,
    WIN_50_OR_HIGHER,
    WIN_10_OR_HIGHER
} from '../constants';

const playSound = async (sound) => {
    if (Platform.OS === 'web') {
      // Web platform using HTML5 Audio API
      const audio = new Audio(sound);
      audio.play();
      // Note: This assumes `sound` is a valid URL for the web platform.
    } else {
      // Native platforms using expo-av
      const { sound: soundObject, status } = await Av.Sound.createAsync(
        sound,
        { shouldPlay: true }
      );
  
      // Unload on finish
      soundObject.setOnPlaybackStatusUpdate(async (status) => {
        if (status.didJustFinish) {
          await soundObject.unloadAsync();
        }
      });
    }
}

const playBeep = () => {
    playSound(BEEP);
}


const playSpinSound = () => {
    playSound(SPIN);
}

const playWin100OrHigher = () => {
    playSound(WIN_100_OR_HIGHER);
}

const playWin50OrHigher = () => {
    playSound(WIN_50_OR_HIGHER);
}

const playWin10OrHigher = () => {
    playSound(WIN_10_OR_HIGHER);
}

export {
    playBeep,
    playSpinSound,
    playWin100OrHigher,
    playWin50OrHigher,
    playWin10OrHigher
};