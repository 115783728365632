import React from 'react'
import { TouchableOpacity, StyleSheet, Text, View } from 'react-native'
import colors from '../../strings/colors'

function NumberKey({number,isOn,onPress,keyPadNumber}) {

    const handlePress = () => {
        
        onPress(keyPadNumber,number)
    }

    return (

      <TouchableOpacity style={[styles.button,isOn && styles.on]} onPress={handlePress}>
          <Text style={styles.text}>{number}</Text>
      </TouchableOpacity>
  
    )
  }
  
  const styles = StyleSheet.create({
     button: {
          width: 20,
          height: 20,
          backgroundColor: colors.yellow,
          margin: 1,
          borderRadius: 3,
          justifyContent: 'center',
          alignItems: 'center',
     },
      text: {
          fontFamily: 'Poppins-Bold',
          color: colors.black,
          fontSize: 12,
      },
      on: {
          backgroundColor:colors.white,
          shadowColor: colors.yellow,
          shadowOffset: { width: 0, height: 0 },
          shadowRadius: 8,
          shadowOpacity: 1,
      }
     
  })

export default React.memo(NumberKey)