/**
 * keypad.system.js
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2023
 * All rights reserved
 */

export const keypadSystem = (entities) => {

    let keypad = entities.keypad;
    
    for (let i = 0; i < keypad.keypad_numbers.length; i++) {
      if (keypad.keypad_numbers[i].number === keypad.keyPadNumber) {
        let newKeys = keypad.keypad_numbers[i].keys.map((key) => {
          if (key.number === keypad.keyClicked) {
            return {
              ...key,
              isOn: !key.isOn,
            };
          } else {
            return key;
          }
        });
  
        keypad.keypad_numbers[i].keys = newKeys;
      }
    }
  
    return entities;
};
  