import React from 'react';
import { TouchableOpacity } from 'react-native';
import { Ionicons } from '@expo/vector-icons';

const MenuIcon = ({ onPress, icon }) => {
  return (
    <TouchableOpacity style={{marginLeft:10}} onPress={onPress}>
      <Ionicons name={icon} size={30} color="white" />
    </TouchableOpacity>
  );
};

export default MenuIcon;
