/**
 * keypad-constants.js
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2023
 * All rights reserved
 */


const KEYS_10K = {
        keys:[
        {number:1,isOn:false},
        {number:2,isOn:false},
        {number:3,isOn:false},
        {number:4,isOn:false},
        ],
        maxi_win:'10,000',
        mini_bet:'1.00',
    };
  const KEYS_15K = {
    keys:[
      {number:1,isOn:false},
      {number:2,isOn:false},
      {number:4,isOn:false},
      {number:8,isOn:false},
    ],
    maxi_win:'15,000',
    mini_bet:'1.00',
  }
  const KEYS_50K = {
    keys:[
      {number:5,isOn:false},
      {number:10,isOn:false},
      {number:15,isOn:false},
      {number:20,isOn:false}
    ],
    maxi_win:'50,000',
    mini_bet:'5.00',
  }

   export {
    KEYS_10K,
    KEYS_15K,
    KEYS_50K
   }