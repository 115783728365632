import React, { useEffect } from 'react'
import { View, StyleSheet, Text } from 'react-native'
import colors from '../../strings/colors'
import MultiplierNumber from './MultiplierNumber'
import { useSelector } from 'react-redux';

function WinningMultiplier() {
  const multipliers = useSelector(state => state.multipliers.multipliers);
 
  return (
    <View style={styles.container}>
        {multipliers.map((number, index) => (
            <MultiplierNumber key={index} number={number.number} isOn={number.isOn} width={number.width}/>
        ))}
    </View>
  )
}

const styles = StyleSheet.create({
    container: {
        backgroundColor:colors.yellow,
        height:40,width:'100%',
        alignContent:'center',
        justifyContent:'center',
        flexDirection:'row',
        borderRadius:10,
        padding:1
    }
})

export default React.memo(WinningMultiplier)