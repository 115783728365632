
/**
 * spinning-constants.js
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2023
 * All rights reserved
 */

const CONSTANT_SPEED = 20; //ms

//spin once steps array data


const SPIN_ONCE = [0,1,2,3,4,5,6,7,8,9,10,11,12,0];

const SPIN_FROM_0 = [0,1,2,3,4,5,6,7,8,9,10,11,12];
//spin from 5
const SPIN_FROM_5 = [1,2,3,4,5,6,7,8,9,10,11,12];
//spin from 12 
const SPIN_FROM_12 = [2,3,4,5,6,7,8,9,10,11,12];
//spin from 3
const SPIN_FROM_3 = [3,4,5,6,7,8,9,10,11,12];
//spin from 10
const SPIN_FROM_10 = [4,5,6,7,8,9,10,11,12];
//spin from 1
const SPIN_FROM_1 = [5,6,7,8,9,10,11,12];
//spin from 8
const SPIN_FROM_8 = [6,7,8,9,10,11,12];
//spin from 9
const SPIN_FROM_9 = [7,8,9,10,11,12];
//spin from 2
const SPIN_FROM_2 = [8,9,10,11,12,];
//spin from 7
const SPIN_FROM_7 = [9,10,11,12,];
//spin from 6
const SPIN_FROM_6 = [10,11,12,];
//spin from 11
const SPIN_FROM_11 = [11,12,];
//spin from 4
const SPIN_FROM_4 = [12];

//spin to 5
const SPIN_TO_5 = [0,1];
//spin to 12
const SPIN_TO_12 = [0,1,2];
//spin to 3
const SPIN_TO_3 = [0,1,2,3];
//spin to 10
const SPIN_TO_10 = [0,1,2,3,4];
//spin to 1
const SPIN_TO_1 = [0,1,2,3,4,5];
//spin to 8
const SPIN_TO_8 = [0,1,2,3,4,5,6];
//spin to 9
const SPIN_TO_9 = [0,1,2,3,4,5,6,7];
//spin to 2
const SPIN_TO_2 = [0,1,2,3,4,5,6,7,8];
//spin to 7
const SPIN_TO_7 = [0,1,2,3,4,5,6,7,8,9];
//spin to 6
const SPIN_TO_6 = [0,1,2,3,4,5,6,7,8,9,10];
//spin to 11
const SPIN_TO_11 = [0,1,2,3,4,5,6,7,8,9,10,11];
//spin to 4
const SPIN_TO_4 = [0,1,2,3,4,5,6,7,8,9,10,11,12];

//decelerate to 0
const DECELERATE_TO_0 = [...SPIN_FROM_6,...SPIN_ONCE];//FINE
//decelerate to 5
const DECELERATE_TO_5 = [...SPIN_FROM_11,...SPIN_FROM_0,...SPIN_TO_5];
//decelerate to 12 
const DECELERATE_TO_12 = [...SPIN_FROM_4,...SPIN_FROM_0,...SPIN_TO_12];
//decelerate to 3
const DECELERATE_TO_3 = [...SPIN_FROM_0,...SPIN_TO_3];
//decelerate to 10
const DECELERATE_TO_10 = [...SPIN_FROM_5,...SPIN_TO_10];
//decelerate to 1
const DECELERATE_TO_1 = [...SPIN_FROM_12,...SPIN_TO_1];
//decelerate to 8
const DECELERATE_TO_8 = [...SPIN_FROM_3,...SPIN_TO_8];
//decelerate to 9
const DECELERATE_TO_9 = [...SPIN_FROM_10,...SPIN_TO_9];
//decelerate to 2
const DECELERATE_TO_2 = [...SPIN_FROM_1,...SPIN_TO_2];
//decelerate to 7
const DECELERATE_TO_7 = [...SPIN_FROM_8,...SPIN_TO_7];
//decelerate to 6
const DECELERATE_TO_6 = [...SPIN_FROM_9,...SPIN_TO_6];
//decelerate to 11
const DECELERATE_TO_11 = [...SPIN_FROM_2,...SPIN_TO_11];
//decelerate to 4
const DECELERATE_TO_4 = [...SPIN_FROM_7,...SPIN_TO_4];

//spin map

const SPIN_MAP = {
    0: SPIN_FROM_0,
    1: SPIN_FROM_1,
    2: SPIN_FROM_2,
    3: SPIN_FROM_3,
    4: SPIN_FROM_4,
    5: SPIN_FROM_5,
    6: SPIN_FROM_6,
    7: SPIN_FROM_7,
    8: SPIN_FROM_8,
    9: SPIN_FROM_9,
    10: SPIN_FROM_10,
    11: SPIN_FROM_11,
    12: SPIN_FROM_12,
 };

 const SPIN_TO_MAP = {
    0: SPIN_TO_7,
    5: SPIN_TO_6,
    12: SPIN_TO_11,
    3: SPIN_TO_4,
    10: SPIN_ONCE,
    1: SPIN_TO_5,
    8: SPIN_TO_12,
    9: SPIN_TO_3,
    2: SPIN_TO_10,
    7: SPIN_TO_1,
    6: SPIN_TO_8,
    11: SPIN_TO_9,
    4: SPIN_TO_2,
 }

const DECELERATE_MAP = {
    0: DECELERATE_TO_0,
    1: DECELERATE_TO_1,
    2: DECELERATE_TO_2,
    3: DECELERATE_TO_3,
    4: DECELERATE_TO_4,
    5: DECELERATE_TO_5,
    6: DECELERATE_TO_6,
    7: DECELERATE_TO_7,
    8: DECELERATE_TO_8,
    9: DECELERATE_TO_9,
    10: DECELERATE_TO_10,
    11: DECELERATE_TO_11,
    12: DECELERATE_TO_12,
};

export {
    CONSTANT_SPEED,
    SPIN_ONCE,
    SPIN_FROM_0,
    SPIN_FROM_5,
    SPIN_FROM_12,
    SPIN_FROM_3,
    SPIN_FROM_10,
    SPIN_FROM_1,
    SPIN_FROM_8,
    SPIN_FROM_9,
    SPIN_FROM_2,
    SPIN_FROM_7,
    SPIN_FROM_6,
    SPIN_FROM_11,
    SPIN_FROM_4,
    SPIN_TO_5,
    SPIN_TO_12,
    SPIN_TO_3,
    SPIN_TO_10,
    SPIN_TO_1,
    SPIN_TO_8,
    SPIN_TO_9,
    SPIN_TO_2,
    SPIN_TO_7,
    SPIN_TO_6,
    SPIN_TO_11,
    SPIN_TO_4,
    DECELERATE_TO_0,
    DECELERATE_TO_5,
    DECELERATE_TO_12,
    DECELERATE_TO_3,
    DECELERATE_TO_10,
    DECELERATE_TO_1,
    DECELERATE_TO_8,
    DECELERATE_TO_9,
    DECELERATE_TO_2,
    DECELERATE_TO_7,
    DECELERATE_TO_6,
    DECELERATE_TO_11,
    DECELERATE_TO_4,
    SPIN_MAP,
    DECELERATE_MAP,
    SPIN_TO_MAP
};