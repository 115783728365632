/**
 * storage.js
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2023
 * All rights reserved
 */

import AsyncStorage  from '@react-native-async-storage/async-storage';

const isWebPlatform = typeof window !== 'undefined' && typeof window.localStorage !== 'undefined';

export const setItem = async (key, value) => {
    if (isWebPlatform) {
        localStorage.setItem(key, value);
    } else {
        await AsyncStorage.setItem(key, value);
    }
};

export const getItem = async (key) => {
    if (isWebPlatform) {
        return localStorage.getItem(key);
    } else {
        return await AsyncStorage.getItem(key);
    }
};

export const removeItem = async (key) => {
    if (isWebPlatform) {
        localStorage.removeItem(key);
    } else {
        await AsyncStorage.removeItem(key);
    }
};
