import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import RoundedInput from '../../components/common/RoundedInput';
import RoundedButton from '../../components/common/RoundedButton';
import colors from '../../strings/colors';
import { useNavigation } from '@react-navigation/core';
import { useUpdateProfileMutation } from '../../redux/api';
import Loader from '../../components/common/Loader';
import SiskusIcon from '../../components/common/SiskusIcon';
import DOBPicker from '../../components/common/DOBPicker';
import { useSelector } from 'react-redux';
import Toast from 'react-native-toast-message';

const Profile = () => {
  const user_state = useSelector(state => state.user);

  const [name, setName] = useState(user_state.name || '');
  const [dob, setDOB] = useState(user_state.dob || '');
  const [cellphone, setCellphone] = useState(user_state.cellphone || '');
  const [status, setStatus] = useState('');

  const [updateProfile, updateProfileResult] = useUpdateProfileMutation();

  const navigation = useNavigation();

  const handleNameChange = text => {
    setName(text);
  };

  const handleDOBChange = text => {
    setDOB(text);
  };

  const handleCellphoneChange = text => {
    setCellphone(text);
  };

  const handleUpdate = () => {
    if (name === '') {
      Toast.show({
        type:'error',
        text1:'Validation Error',
        text2:'Please enter your name'
      });
      return;
    }
    if (dob === '') {
      Toast.show({
        type:'error',
        text1:'Validation Error',
        text2:'Please enter your date of birth'
      });
      return;
    }
    if (cellphone === '') {
      Toast.show({
        type:'error',
        text1:'Validation Error',
        text2:'Please enter your cellphone'
      });
      return;
    }

    updateProfile({
      _id: user_state._id,
      name: name,
      dob: dob,
      cellphone: cellphone,
    });
  };

  useEffect(() => {
    if (updateProfileResult.isSuccess) {
       Toast.show({
        type:'success',
        text1:'Success',
        text2:'Profile updated successfully'
      });
    }
    if (updateProfileResult.isError) {
      Toast.show({
        type:'error',
        text1:'Error',
        text2:updateProfileResult.error.message
      });
      console.log(updateProfileResult.error);
    }
  }, [updateProfileResult.isLoading]);

  if (updateProfileResult.isLoading) {
    return (
      <Loader/>
    );
  }

  return (
    <View style={styles.container}>
      <View style={styles.layout}>
        <SiskusIcon title={name} description={'Update Your Profile'} />
        <RoundedInput placeholder="Your Name" value={name} onChangeText={handleNameChange} />
        <DOBPicker onDOBChange={handleDOBChange} dob={dob} />
        <RoundedInput placeholder="Your Cellphone" keyboardType='phone-pad' value={cellphone} onChangeText={handleCellphoneChange} />
        <Text style={styles.statusText}>{status}</Text>
        <RoundedButton title="UPDATE PROFILE" onPress={handleUpdate} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
    justifyContent: 'center',
    alignItems: 'center',
  },
  layout: {
    width: '90%',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 20,
  },
  statusText: {
    color: colors.orange,
    marginTop: 10,
    marginBottom: 10,
    textAlign: 'center',
  },
});

export default Profile;
