/**
 * Game.jsx
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2023
 * All rights reserved
 * 
 */

import React, { useEffect, useState, useRef } from 'react'
import { GameEngine } from "react-native-game-engine";
import {Platform,SafeAreaView ,Dimensions,StyleSheet,View } from 'react-native'
import colors from '../../../strings/colors';
import SpinnerEntity from './entities/spinner.entity';
import { multiplierEntity, keypadEntity } from './entities';
import { MultiplierSystem, SpinningSystem, keypadSystem, K } from './systems';
import FlatButton from '../../../components/jackpot/FlatButton';
import RouletteWheel from '../../../components/jackpot/RouletteWheel';
import LEDDisplay from '../../../components/jackpot/LEDDisplay';
import WinningMultiplier from '../../../components/jackpot/WinningMultiplier';
import KeypadNumber from '../../../components/jackpot/KeypadNumber';
import { getFiveDigitsAmount } from './helpers';
import { useSelector } from 'react-redux';
import { useGetBalanceQuery, usePlayMutation } from '../../../redux/api';
import { useNavigation } from '@react-navigation/core';


function Game() {

    const user_state = useSelector(state => state.user);

    const engine = useRef(null);

    const [play, playResult] = usePlayMutation();

    const [isRunning, setIsRunning] = useState(true);

    const {data:balance_data,isLoading:balance_isLoading, isSuccess: loadingBalanceSuccessfull} = useGetBalanceQuery(user_state._id);

    const navigation = useNavigation();

    const entities = {
        spinner: SpinnerEntity,
        keypad: keypadEntity,
        multiplier: multiplierEntity,
        display: {
            credit: 0,
            win: 0,
        }
    };

    const systems = [
        keypadSystem,
        SpinningSystem,
        MultiplierSystem,
    ];

    const handlePayin = () => {
      navigation.navigate('Payin');
    };

    const handlePayout = () => {
      navigation.navigate('Payout');
    };

    const handleCancel = () => {
     
   };
  
  const handleStart = () => {
      engine.current.dispatch('spin');
  }

    useEffect(() => {
      if(loadingBalanceSuccessfull){
         
      }
    }, [balance_isLoading]);



  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: colors.gray }}>
    <GameEngine
      ref={engine}
      style={styles.container}
      systems={systems}
      entities={entities}
      running={isRunning}
      >
        <FlatButton color={'green'} title="START" onPress={() =>  handleStart()} />
      {/* <View style={[styles.container, { backgroundColor: colors.dark_blue }]}>
      <View style={styles.upperContainer}>
          <View style={styles.leftContainer}>
               <FlatButton color={'yellow'} title="PAYIN" onPress={() =>  handlePayin()} />
               <FlatButton color={'red'} title="PAYOUT" onPress={() =>  handlePayout()} />
          </View>
          <View style={styles.centerContainer}>
            <RouletteWheel numbers={entities.spinner.numbers}/>
          </View>
          <View style={styles.rightContainer}>
            <FlatButton color={'red'} title="CANCEL" onPress={() =>  handleCancel()} />
            <FlatButton color={'green'} title="START" onPress={() =>  handleStart()} />
          </View> 
        </View>
        <View style={styles.displays}>
            <View style={[styles.leftContainer,{marginLeft:1}]}>
                <LEDDisplay name={'WIN'} amount={getFiveDigitsAmount(entities.display.win)}/>
            </View>
            <View style={[styles.centerContainer,{width:200}]}>
               <WinningMultiplier winning_multipliers={ entities.multiplier.winning_multipliers} />
            </View>
            <View style={styles.rightContainer}>
            <LEDDisplay name={'CREDIT'} amount={getFiveDigitsAmount(entities.display.credit)}/>
            </View>
        </View>
        <View style={styles.key_pad}>
         
          <View style={styles.keyPadRow}>
            <View style={styles.keyPadNumber}>
              
            </View>
            <View style={styles.keyPadNumber}>
            <KeypadNumber number={entities.keypad.keypad_numbers[0]} backgroundColor={colors.green} 
               onPress={(keyPadNumber, keyClicked) => {
                entities.keypad.keyPadNumber = keyPadNumber;
                    entities.keypad.keyClicked = keyClicked;
                }
              }
            />
            </View>
            <View style={styles.keyPadNumber}>
              
            </View>
          </View>
          
          <View style={styles.keyPadRow}>
            <View style={styles.keyPadNumber}>
              <KeypadNumber number={entities.keypad.keypad_numbers[1]} backgroundColor={colors.red}onPress={(keyPadNumber, keyClicked) => {
                    entities.keypad.keyPadNumber = keyPadNumber;
                    entities.keypad.keyClicked = keyClicked;
                }}/>
            </View>
            <View style={styles.keyPadNumber}>
              <KeypadNumber number={entities.keypad.keypad_numbers[2]} backgroundColor={colors.black}onPress={(keyPadNumber, keyClicked) => {
                    entities.keypad.keyPadNumber = keyPadNumber;
                    entities.keypad.keyClicked = keyClicked;
                }}/>
            </View>
            <View style={styles.keyPadNumber}>
              <KeypadNumber number={entities.keypad.keypad_numbers[3]} backgroundColor={colors.red}onPress={(keyPadNumber, keyClicked) => {
                    entities.keypad.keyPadNumber = keyPadNumber;
                    entities.keypad.keyClicked = keyClicked;
                }}/>
            </View>
          </View>
          
          <View style={styles.keyPadRow}>
            <View style={styles.keyPadNumber}>
              <KeypadNumber number={entities.keypad.keypad_numbers[4]} backgroundColor={colors.black}onPress={(keyPadNumber, keyClicked) => {
                    entities.keypad.keyPadNumber = keyPadNumber;
                    entities.keypad.keyClicked = keyClicked;
                }}/>
            </View>
            <View style={styles.keyPadNumber}>
              <KeypadNumber number={entities.keypad.keypad_numbers[5]} backgroundColor={colors.red}onPress={(keyPadNumber, keyClicked) => {
                    entities.keypad.keyPadNumber = keyPadNumber;
                    entities.keypad.keyClicked = keyClicked;
                }}/>
            </View>
            <View style={styles.keyPadNumber}>
              <KeypadNumber number={entities.keypad.keypad_numbers[6]} backgroundColor={colors.black}onPress={(keyPadNumber, keyClicked) => {
                    entities.keypad.keyPadNumber = keyPadNumber;
                    entities.keypad.keyClicked = keyClicked;
                }}/>
            </View>
          </View>
          
          <View style={styles.keyPadRow}>
            <View style={styles.keyPadNumber}>
              <KeypadNumber number={entities.keypad.keypad_numbers[7]} backgroundColor={colors.red}onPress={(keyPadNumber, keyClicked) => {
                    entities.keypad.keyPadNumber = keyPadNumber;
                    entities.keypad.keyClicked = keyClicked;
                }}/>
            </View>
            <View style={styles.keyPadNumber}>
              <KeypadNumber number={entities.keypad.keypad_numbers[8]} backgroundColor={colors.black}onPress={(keyPadNumber, keyClicked) => {
                    entities.keypad.keyPadNumber = keyPadNumber;
                    entities.keypad.keyClicked = keyClicked;
                }}/>
            </View>
            <View style={styles.keyPadNumber}>
              <KeypadNumber number={entities.keypad.keypad_numbers[9]} backgroundColor={colors.red}onPress={(keyPadNumber, keyClicked) => {
                    entities.keypad.keyPadNumber = keyPadNumber;
                    entities.keypad.keyClicked = keyClicked;
                }}/>
            </View>
          </View>
         
          <View style={styles.keyPadRow}>
            <View style={styles.keyPadNumber}>
              <KeypadNumber number={entities.keypad.keypad_numbers[10]} backgroundColor={colors.black}onPress={(keyPadNumber, keyClicked) => {
                    entities.keypad.keyPadNumber = keyPadNumber;
                    entities.keypad.keyClicked = keyClicked;
                }}/>
            </View>
            <View style={styles.keyPadNumber}>
              <KeypadNumber number={entities.keypad.keypad_numbers[11]} backgroundColor={colors.red}onPress={(keyPadNumber, keyClicked) => {
                    entities.keypad.keyPadNumber = keyPadNumber;
                    entities.keypad.keyClicked = keyClicked;
                }}/>
            </View>
            <View style={styles.keyPadNumber}>
              <KeypadNumber number={entities.keypad.keypad_numbers[12]} backgroundColor={colors.black}onPress={(keyPadNumber, keyClicked) => {
                    entities.keypad.keyPadNumber = keyPadNumber;
                    entities.keypad.keyClicked = keyClicked;
                }}/>
            </View>
          </View>
        </View>
      </View> */}
    </GameEngine>
  </SafeAreaView>
  )
}

const styles = StyleSheet.create({
    container: {
      
      backgroundColor:colors.green, 
      height: Platform.OS === 'web' ? 700 : '100%',
      width: Dimensions.get('window').width>1200? 400 : '100%',
      alignItems:'center',
      justifyContent:'flex-start',
      padding: 2,
      margin: 'auto',
      flexDirection:'column',
    },
    upperContainer: {
      
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection:'row',
      marginTop: 10,
      height: 180,
      borderRadius: 10,
      width:'98%',
      margin:'auto',
      padding:10,
      backgroundColor:colors.light_blue
    },
    displays: {
      
      flexDirection:'row',
      marginTop: 2,
      borderRadius: 10,
      justifyContent:'center',
      alignItems:'center',
      width:'98%',
      height: 100,
      margin: 'auto',
      padding:0,
      
    },
    leftContainer: {
      flex:1,
      alignItems: 'flex-start',
      justifyContent: 'center',
      flexDirection:'column',
    },
    rightContainer: {
      flex:1,
      alignItems: 'flex-end',
      justifyContent: 'center',
      flexDirection:'column',
      
    },
    centerContainer: {
      flex:2,
      justifyContent: 'center',
      flexDirection:'column',
      
    },
    key_pad: {
      flex:2,
      justifyContent: 'center',
      flexDirection:'column',
      alignItems:'center',
      width:'90%',
      height: '100%',
      backgroundColor:colors.light_blue,
      borderRadius: 10,
      marginTop: 1,
      padding:10,
    },
    keyPadRow: {
      flex:1,
      flexDirection:'row',
      justifyContent:'center',
      width:'100%',
      flexDirection:'row',
    },
    keyPadNumber: {
      flex:1,
      justifyContent:'center',
      alignItems:'center',
    }
    
})

export default Game