// services/api.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import urls from '../strings/urls';

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ 
    baseUrl: 'https://siskusserver.com/api',
    prepareHeaders: (headers) => {
        headers.set('Content-Type', 'application/json');
        headers.set('Accept', 'application/json');
        headers.set('Access-Control-Allow-Origin', '*');
        headers.set('Token', `Bearer` || null);
        return headers;
    },
}),
  endpoints: (builder) => ({
    //auth endpoints
    login: builder.mutation({
        query: (body) => ({
            url: '/login',
            method: 'POST',
            body,
        }),
    }),
    register: builder.mutation({
        query: (body) => ({
            url: '/register',
            method: 'POST',
            body,
        }),
    }),
    //forgot password endpoints
    forgotPassword: builder.mutation({
        query: (body) => ({
            url: '/forgetpassword',
            method: 'POST',
            body,
        }),
    }),

    //changePassword

    changePassword: builder.mutation({
        query: (body) => ({
            url: '/changePassword',
            method: 'POST',
            body,
        }),
    }),

    //phone-search

    phoneSearch: builder.mutation({
        query: (body) => ({
            url: '/phone-search',
            method: 'POST',
            body,
        }),
    }),

    //complete-profile

    completeProfile: builder.mutation({
        query: (body) => ({
            url: '/complete-profile',
            method: 'POST',
            body,
        }),
    }),

    //updateProfile

    updateProfile: builder.mutation({
        query: (body) => ({
            url: '/updateUser',
            method: 'POST',
            body,
        }),
    }),


    /**
     * JACKPOT ENDPOINTS
     */

    //get Balance

    getBalance: builder.query({
        query: (id) => ({
            url: `/balance/${id}`,
        }),
    }),

    //play

    play: builder.mutation({
        query: (body) => ({
            url: '/play',
            method: 'POST',
            body,
        }),
    }),

    //send credit

    sendCredit: builder.mutation({
        query: (body) => ({
            url: '/sendMoney',
            method: 'POST',
            body,
        }),
    }),

    //get payout options

    getPayoutOptions: builder.query({
        query: () => ({
            url: '/pos',
            method: 'GET',
        }),
    }),

    //payout

    payout: builder.mutation({
        query: (body) => ({
            url: '/payout',
            method: 'POST',
            body,
        }),
    }),

    //settings/:userId

    getSettings: builder.query({
        query: (id) => ({
            url: `/settings/${id}`,
            method: 'GET',
        }),
    }),

    //updateSettings

    updateSettings: builder.mutation({
        query: (body) => ({
            url: '/updateSettings',
            method: 'POST',
            body,
        }),
    }),
    
  }),
});

export const { 
    //auth endpoints
    useLoginMutation,
    useRegisterMutation,
    useForgotPasswordMutation,
    useChangePasswordMutation,
    //phone-search
    usePhoneSearchMutation,
    //complete-profile
    useCompleteProfileMutation,
    //update-profile
    useUpdateProfileMutation,
    //jackpot endpoints
    useGetBalanceQuery,
    usePlayMutation,
    useSendCreditMutation,
    useGetPayoutOptionsQuery,
    usePayoutMutation,
    useGetSettingsQuery,
    useUpdateSettingsMutation,
    
 } = api;
