import React, { useState, useEffect } from 'react';
import { View, Text, Linking, Button, TouchableOpacity, StyleSheet, CheckBox } from 'react-native';
import RoundedInput from '../../components/common/RoundedInput';
import RoundedButton from '../../components/common/RoundedButton';
import colors from '../../strings/colors';
import { useNavigation } from '@react-navigation/core';
import { useCompleteProfileMutation } from '../../redux/api';
import Loader from '../../components/common/Loader';
import SiskusIcon from '../../components/common/SiskusIcon';
import DOBPicker from '../../components/common/DOBPicker';
import Terms from '../../components/common/Terms';
import { useSelector } from 'react-redux';
import Toast from 'react-native-toast-message';

const Register = () => {

  const user_state = useSelector(state => state.user);

  const [name, setName] = useState('');
  const [dob, setDOB] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [status, setStatus] = useState('');

  const [termsAccepted, setTermsAccepted] = useState(false);

  const [completeProfile, registerResult] = useCompleteProfileMutation();

  const navigation = useNavigation();

  const handleNameChange = text => {
    setName(text);
  };

  const handleDOBChange = text => {
    setDOB(text);
  };

  const handleEmailChange = text => {
    setEmail(text);
  };

  const handleCellphoneChange = text => {
    setCellphone(text);
  };

  const handlePasswordChange = text => {
    setPassword(text);
  };

  const handleRegister = () => {
    
    //validate
    if (name === '') {
     Toast.show({
        type:'error',
        text1:'Validation Error',
        text2:'Please enter your name'
    });
      return;
    }
    if (dob === '') {
      Toast.show({
          type:'error',
          text1:'Validation Error',
          text2:'Please enter your date of birth'
      });
      return;
    }
    if (email === '') {
      Toast.show({
          type:'error',
          text1:'Validation Error',
          text2:'Please enter your email'
      });
      return;
    }
    if (password === '') {
      Toast.show({
          type:'error',
          text1:'Validation Error',
          text2:'Please enter your password'
      });
      return;
    }
    completeProfile({
      _id: user_state._id,
      name: name,
      dob: dob,
      email: email,
      password: password,
    });
  };

  useEffect(() => {
    if(user_state){
      if(user_state._id === ''){
          navigation.navigate('Search');
       }
     }
      
 }, []);
 

  useEffect(() => {
    if (registerResult.isSuccess) {
      setStatus(registerResult.data.message);
      if (registerResult.data.success === true) {
        // Navigate to a success page or handle accordingly
        navigation.navigate('Jackpot');
      }
    }
    if (registerResult.isError) {
      Toast.show({
          type:'error',
          text1:'Error',
          text2:registerResult.error.message
      });
      console.log(registerResult.error);
    }
  }, [registerResult.isLoading]);

  if (registerResult.isLoading) {
    return (
      <Loader/>
    );
  }

  return (
    <View style={styles.container}>
      <View style={styles.layout}>
        <SiskusIcon title={'Complete Your Profile'} description={'SISKUS'} />
        <RoundedInput placeholder="Your Name" onChangeText={handleNameChange} />
        <DOBPicker onDOBChange={handleDOBChange} />
        <RoundedInput placeholder="Your Email" keyboardType='email-address' onChangeText={handleEmailChange} />
        <RoundedInput placeholder="Create New Password" keyboardType='password' onChangeText={handlePasswordChange} isPassword={true} />
        <Text style={styles.statusText}>{status}</Text>
        <Terms onTermsAccepted={setTermsAccepted} />
        <RoundedButton title="REGISTER" onPress={handleRegister} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
    justifyContent: 'center',
    alignItems: 'center',
  },
  layout: {
    width: '90%',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 20,
  },
  statusText: {
    color: colors.orange,
    marginTop: 10,
    marginBottom: 10,
    textAlign: 'center',
  },
  checkboxContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 15,
  },
  checkboxText: {
    marginLeft: 10,
  },
  linkText: {
    color: 'blue',
    textDecorationLine: 'underline',
  },

});

export default Register;
