/**
 * Loader.jsx
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2023
 * All rights reserved
 */
import React from 'react';
import { View, ActivityIndicator, StyleSheet } from 'react-native';
import colors from '../../strings/colors';

function Loader() {
  return (
    <View style={styles.container}>
      <ActivityIndicator size="large" color={colors.gray} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.light_gray,  // Optional: If you want a semi-transparent background for the loader
    borderRadius: 8,
  },
});

export default Loader;
