import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        _id: '',
        name: '',
        email: '',
        password: '',
        cellphone: '',
        dob: '',
        isDeleted: false,
        createdAt: '',
        updatedAt: '',
        isconfirmed: false,
        loggedIn: false
    },

    reducers: {
        setUser: (state, action) => {
            state._id = action.payload._id
            state.name = action.payload.name
            state.email = action.payload.email
            state.password = action.payload.password
            state.cellphone = action.payload.cellphone
            state.dob = action.payload.dob
            state.isDeleted = action.payload.isDeleted
            state.createdAt = action.payload.createdAt
            state.updatedAt = action.payload.updatedAt
            state.isconfirmed = action.payload.isconfirmed
        },
        removeUser: (state) => {
            state._id = ''
            state.name = ''
            state.email = ''
            state.password = ''
            state.cellphone = ''
            state.dob = ''
            state.isDeleted = false
            state.createdAt = ''
            state.updatedAt = ''
            state.isconfirmed = false
            state.loggedIn = false
        },

        setIsLoggedIn: (state, action) => {
            state.loggedIn = action.payload
        }


    },

    extraReducers: (builder) => {
        builder.addCase('LOAD_INITIAL_STATE', (state, action) => {
            return action.payload.user || state;
        });
    }
})

export const userActions = userSlice.actions;
